@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&family=Playfair+Display:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Climate+Crisis&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #CBA135;
  --dark-primary-color: #876B23;
  --dark-color: #000A1F;
  --light-color: #F3F2EF;
  --black: #29200B;
  --container: 75%;
  --width-container: 80%;
  --mobile-container: 90%;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* For webkit-based browsers (e.g., Chrome, Safari) */
/* ::-webkit-scrollbar {
  width: 12px;
} */

/* Track (the background of the scrollbar) */
/* ::-webkit-scrollbar-track {
  background: #CBA135;
} */

/* Handle (the draggable part of the scrollbar) */
/* ::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 6px;
} */

/* Remove arrow buttons for number input in WebKit browsers (Chrome, Safari) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove arrow buttons for number input in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


/* Add this CSS in your stylesheet or within a style tag in your HTML */
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Style the button when in loading state */
.loading {
  background-color: gray;
  /* Change to the desired background color */
  cursor: not-allowed;
  opacity: 0.6;
  /* Adjust the opacity as needed */
}





body {
  font-family: 'Nunito', sans-serif;
  color: var(--dark-color);
  background-color: var(--light-color);
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

.swiper-pagination-bullet {
  background: rgb(255, 255, 255, 1) !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background: var(--primary-color) !important;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: white;
  height: 50px !important;
  width: 50px !important;
  border-radius: 50%;
}

.margin-120 {
  margin-top: 120px !important;
}

/*Flip card*/
.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}

/* homepage */

/* desktop */

/* hamburger */
.mobile-container {
  background-color: var(--light-color);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100000000;
}

.mobile-logo img {
  width: 150px;
  height: 70px;
  object-fit: contain;
  /* transform: scale(0.9); */
}

.hamburger-icon {
  height: 50px;
  width: 50px;
  background-color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 20px;
  font-weight: 100;
}

.close-hamburger {
  height: 50px;
  width: 50px;
  background-color: inherit;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: 100;
}

.hamburger-top {
  position: absolute;
  top: 0;
  margin-bottom: 50px;
  justify-content: space-between;
  align-items: center;
  background-color: inherit;
  padding: 10%;
}

.hamburger-links-list {
  overflow: scroll;
  margin-top: 50px;
}

.hamburger-top img {
  width: 150px;
  height: 40px;
  object-fit: contain;
}

.hamburger-menu {
  height: 0%;
  width: 0%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #1E1E1E;
  display: flex;
  flex-direction: column;
  z-index: 10000000000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out 0.4s;
}

.gap-30 {
  gap: 30px !important;
}

.width-100 {
  width: 100%;
}

.padding-10 {
  padding: 0 10%;
}

.open-hamburger {
  visibility: visible;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  /* overflow: hidden; */
}

.closed-hamburger {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 0%;
  height: 0%;
  opacity: 0;
  overflow: hidden;
  transition: all 0.2s ease-in-out 0.2s;
}

.hamburger-menu ul.open {
  list-style-type: none;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  gap: 20px;
}

.open {
  display: flex;
}

.close {
  display: none;
}

.hamburger-menu ul {
  width: 100%;
}

.hamburger-menu ul.close {
  display: none;
  gap: 10px;
}

.hamburger-menu li {
  font-size: 18px;
  color: var(--light-color);
}

.hamburger-menu li a {
  text-decoration: none;
  color: var(--light-color);
  font-weight: 900;
  margin-left: 10px;
}

.hamburger-menu li hr {
  width: 100%;
  height: 2px;
  background-color: #F3F2EF;
  color: #F3F2EF;
}

.hamburger-menu li a.sm {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.homepage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* upper nav */
.homepage-container .nav-container {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding-left: 7.5%;
  padding-right: 7.5%;
  font-weight: bolder;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999999999999;
  background-color: var(--light-color);
}

.homepage-container .nav-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: url(../public/images/nav-bg.png);
  opacity: 0.8;
  /* Adjust the opacity value as needed */
  z-index: -1;
}

.homepage-container .nav-container .nav-links,
.homepage-container .nav-container .nav-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.homepage-container .nav-container .nav-links .nav-link-item {
  font-weight: bolder;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.homepage-container .nav-container .nav-links .nav-link-item.active {
  color: var(--primary-color) !important;
}

.homepage-container .nav-container .nav-links .nav-link-item:hover {
  color: var(--primary-color);
}

.homepage-container .nav-container .nav-buttons .nav-buttons-item,
.homepage-container .box-center #six,
.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 125px;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 1.5s ease-in-out;
}

.homepage-container .nav-container .nav-buttons .nav-buttons-item#nav-buttons-item-one,
.white-button {
  border: 2px solid var(--primary-color);
}

.homepage-container .nav-container .nav-buttons .nav-buttons-item#nav-buttons-item-two,
.homepage-container .box-center #six,
.button.brown {
  border: 2px solid var(--primary-color);
  /* background: var(--primary-color); */
  background: var(--primary-color);
  color: var(--black);
}

.homepage-container .nav-container .nav-buttons .nav-buttons-item#nav-buttons-item-one:hover {
  border: 2px solid var(--primary-color);
  color: var(--dark-color);
  background: var(--primary-color);
}

.homepage-container .nav-container .nav-buttons .nav-buttons-item#nav-buttons-item-two:hover,
.button.brown:hover {
  border: 2px solid var(--primary-color);
  background: inherit;
  color: var(--primary-color);
}

.homepage-container .nav-container .nav-logo img {
  width: 220px;
  object-fit: contain;
  height: 50px;
  cursor: pointer;
  margin-left: -35px;
}


/* lower nav */
.homepage-container .lower-nav-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  font-weight: bolder;
  background-color: var(--black);
  color: white;
  font-size: 16px;
  z-index: 2;
  position: fixed;
  top: 80px;
  z-index: 999999;
  padding-right: 2.5%;
  padding-left: 2.5%;
}

.homepage-container .lower-nav-container.hide-1000 {
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 7.5%;
  padding-right: 7.5%;
  justify-content: space-between;
}

.homepage-container .lower-nav-container.hide-1000>div {
  width: 100% !important;
  display: flex;
  /* gap: 20px; */
}

.homepage-container .lower-nav-container.hide-1000>div.one {
  /* padding-right: 50px; */
  text-transform: uppercase;
}

.homepage-container .lower-nav-container.hide-1000>div.two {
  /* padding-left: 50px; */
  text-transform: uppercase;
}

.homepage-container .lower-nav-container .active {
  color: black;
  background-color: var(--light-color);
  /* min-width: 100px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0px 5px;

}

.homepage-container .lower-nav-container .container-flex-lower-nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 2px 0px;

}

.homepage-container .lower-nav-container .container-flex-lower-nav .lower-nav-link-item:hover {
  text-decoration: none;
  cursor: pointer;
}

/* hero */
.homepage-container .hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(79vh - 40px);
  width: 100%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: 50% !important;
  margin-top: 120px;
}

/* hero overlay */
.homepage-container .hero-container .swipper-overlay-hero {
  position: absolute;
  top: 120px;
  left: 0;
  width: 100%;
  height: calc(79vh - 40px);
  z-index: -1;
}

.homepage-container .hero-container .overlay {
  height: 100%;
}

.homepage-container .hero-container .overlay img {
  width: 100%;
  height: 100% !important;
}


/* white box */
.homepage-container .box-center {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  flex-direction: row;
  justify-content: space-around;
  border-radius: 10px;
  align-items: center;
  height: 90px;
  background-color: var(--light-color);
  position: relative;
  bottom: 45px;
  /* z-index: 10; */
  font-size: 12px;
  font-weight: 900;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  width: var(--container);
}

.gap-10 {
  gap: 10px;
}

.homepage-container .box-center .item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  align-items: center;
}

.box-center input {
  border: none;
  background-color: inherit;
  outline: none;
  max-width: 100px;
  color: black;
  font-size: 11px;
  cursor: pointer;
}

.homepage-container .box-center .item .flex-row.center.gap-10 i {
  font-size: 20px;
}

.homepage-container .box-center #five {
  justify-self: flex-start;
}

.homepage-container .box-center #five .flex-row.center.gap-10 {
  gap: 20px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.intro-flex {
  gap: 30px;
  text-align: center;
  animation-name: scaleAnimation;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}

.no-animation {
  animation: none !important;
}

.apartment-container#four {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}

.apartment-menu-overlay {
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.9);
  -webkit-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.9);
  -moz-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.9);
  margin: auto;
  width: max-content;
  min-width: 120px;
  padding: 10px;
  position: absolute;
  top: 50px;
  background-color: var(--light-color);
  visibility: hidden;
  z-index: 2 !important;
}

.apartment-menu-overlay.guests {
  min-width: 50px;
}

.hidden {
  visibility: none !important;
}

.visible {
  visibility: visible !important;
}

/* .apartment-text:hover +  .apartment-menu-overlay {
  visibility: visible;
} */
.cursor {
  cursor: pointer;
}

.disabled {
  pointer-events: none;
}

@keyframes scaleAnimation {
  0% {
    transform: translateX(120%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.intro-flex .big-text {
  font-size: 40px;
  z-index: 10;
}

.intro-flex .sm-text {
  font-size: 25px;
  color: white;
  font-weight: 900;
  z-index: 10;
}

.intro-flex .big-text #big-text-white {
  color: white;
  font-weight: 900;
  z-index: 10;
  text-transform: uppercase;
}

.intro-flex .big-text #orange-text {
  margin-top: 10px;
  color: var(--primary-color);
  font-weight: 900;
  text-transform: uppercase;
  z-index: 1000000000;
}

.center {
  justify-content: center;
  align-items: center;
}

.thin-vertical {
  height: 50px;
  width: 0.5px;
  background-color: rgba(0, 0, 0, 0.645);
  opacity: 0.5;
}

.thin-vertical2 {
  height: 35px;
  width: 0.5px;
  background-color: white;
}

/* services */
.services-container {
  margin-top: 50px;
  margin-bottom: 50px;
  justify-content: center;
  align-items: center;
  gap: 40px;
  z-index: -1;
  width: var(--container);
}

.section-text {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  font-family: 'Playfair Display', Nunito;
}

.services-container .items-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}

.services-container .items-grid .item.flex-column {
  justify-content: center;
  align-items: center;
  padding: 30px;
  gap: 20px;
  height: 200px !important;
}

.services-container .items-grid .item.flex-column.one,
.services-container .items-grid .item.flex-column.three {
  border-right: 0.5px solid #cba035ba;
}

.services-container .items-grid .item.flex-column.one,
.services-container .items-grid .item.flex-column.two {
  border-bottom: 0.5px solid #cba035ba;
}

.services-container .items-grid .item.flex-column.three,
.services-container .items-grid .item.flex-column.four {
  border-top: 0.5px solid #cba035ba;
}

.services-container .items-grid .item.flex-column.two,
.services-container .items-grid .item.flex-column.four {
  border-left: 0.5px solid #cba035ba;
}

.services-container .items-grid .item.flex-column .top {
  gap: 15px;
  font-weight: 900;
}

.services-container .items-grid .item.flex-column .top i {
  color: var(--primary-color);
  font-size: 30px;
}

.services-container .items-grid .item.flex-column .title {
  font-size: 25px;
}

.services-container .items-grid .item.flex-column .desc {
  font-size: 16px;
}

.services-container .items-grid .item.flex-column .top,
.services-container .items-grid .item.flex-column .desc {
  justify-content: center;
  align-items: center;
  align-self: flex-start;

}

.services-container .items-grid .item.flex-column .top .icon,
.services-container .items-grid .item.flex-column .top .title {
  text-align: left;
}

.services-container .items-grid .item.flex-column .top .icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: #CBA135;
}

.services-container .items-grid .item.flex-column .top .icon i {
  background: linear-gradient(134.72deg, #CBA135 -8.18%, #1F1600 105.06%);
  -webkit-background-clip: text;
  /* For Safari */
  -webkit-text-fill-color: transparent;
  /* For Safari */
  background-clip: text;
  color: transparent;
  transform: scale(0.8);
}


/* Apartments */
.apartments-container {
  margin-top: 50px;
  margin-bottom: 50px;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding-left: 7.5%;
  padding-right: 7.5%;
  width: 100%;
}

.apartments-container .apartments-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 30px;
  min-width: 100%;
  max-width: 100%;
  /* overflow: hidden; */
}

.apartments-slide-three {
  width: var(--container) !important;
  margin-bottom: 50px;
}

.apartments-grid .item {
  gap: 5px;
  /* width: 100%;
  max-width: 100%; */
}

.apartments-grid .item>div>div {
  /* width: 100% !important; */
}

.apartments-grid .item>div>div img {
  width: 100% !important;
}

.gap-20 {
  gap: 20px;
}

.gap-5 {
  gap: 5px;
}

.swipper-apartment-image img {
  max-width: 100%;
  height: auto;
}

.swipper-apartment-image {
  width: 300px;
  height: 180px;
  border-radius: 5px;
  transition: all 0.8s ease-in-out;
}


/* .apartments-container .apartments-grid .item .apartment-image .img-apartment img{
  width: 300px;
  height: 180px;
  border-radius: 5px;
} */
.apartments-container .apartments-grid .item.instagram .apartment-image .img-apartment img {
  width: 350px;
  height: 250px;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.apartments-container .apartments-grid .item.instagram .apartment-image .img-apartment img:hover {
  transform: scale(1.1);
}

.apartments-page {
  margin-top: 200px;
  margin-bottom: 100px;
}

.apartment-title-and-price,
.apartment-desc,
.apartment-image,
.apartment-image>div,
.swipper-apartment-image {
  width: 100% !important;
  overflow: hidden;
}

.apartment-price-flex {
  text-align: right;
}

.apartment-title-and-price {
  display: flex;
  justify-content: space-between !important;
  width: 100% !important;
}

.apartment-title-and-price .apartment-title {
  font-size: 18px;
  color: var(--dark-primary-color);
  font-weight: 900;
}

.apartment-title-and-price .apartment-price {
  font-size: 12px;
  font-weight: 900;
}

.apartment-title-and-price .sm {
  font-size: 10px;
  font-weight: 900;
}

.m-sm {
  font-size: 16px;
  margin: 20px;
  font-weight: 100;
  color: rgba(0, 0, 0, 0.718);
}

.apartment-desc .apartment-size {
  font-size: 10px;
  font-weight: 900;
}

.apartment-desc .apartment-icons {
  font-size: 10px;
}

.book-cta {
  text-align: right;
  margin-top: 20px;
  font-weight: 900;
  font-size: 14px;
  cursor: pointer;
  height: 40px;
  background-color: var(--primary-color);
  width: fit-content;
  min-width: 80px;
  align-self: flex-end;
  color: var(--light-color) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.apartments-slide-item {
  width: 100%;
}

.book-cta:hover {
  text-decoration: underline;
}

.reviews-container {
  margin-top: 50px;
  margin-bottom: 50px;
  margin: auto;
  min-width: var(--container) !important;
  max-width: var(--container) !important;
}

.reviews-container .swiper-reviews {
  width: 100% !important;
}

.sm-img img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.space-between {
  justify-content: space-between !important;
}

.mySwiper {
  margin: auto;
}

.reviews-container .item-container {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 100px;
  margin-right: 100px;
  padding: 40px 30px;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.brown {
  color: var(--dark-color);
}

.reviews-sm {
  font-size: 12px;
  font-weight: 900;
  color: var(--primary-color);
}

.quicksand-font {
  font-family: "Quicksand", sans-serif;
  color: var(--dark-color);
  font-weight: 600;
}

.reviews-review {
  font-size: 14px;
  font-weight: 900;
}

.swiper-review-item {
  height: 200px;
  width: 100%;
  margin: auto !important;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  padding: 20px;
  justify-content: center;
  background-color: white;
  border: none;
}

.cta-review {
  margin: auto;
  margin-top: 20px;
  cursor: pointer;
  color: var(--dark-color) !important;
  font-weight: 900;
}

.cta-review:hover {
  background-color: var(--primary-color);
}

.newsletter-container {
  margin: 100px 0px;
}

.newsletter-input {
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  margin: 70px;
}

.newsletter-input input {
  width: 100%;
  border: none;
  border-bottom: 2px solid #000a1f43;
  height: 35px;
  background: inherit;
  font-size: 14px;
  cursor: pointer;
  text-align: left;
  outline: none;
}



/* Footer */
.footer-container {
  width: 100%;
  padding-left: 7.5%;
  padding-right: 7.5%;
  background: url(../public/images/web\ black\ 1.png);
  color: white;
}

.footer-container .flex-space-between {
  gap: 70px;
  /* width: 80%; */
  height: 250px;
  padding-top: 30px;
}

.footer-container .flex-space-between.mobile {
  display: none;
}

.footer-container .logo-footer img {
  width: 130px;
  height: 40px;
  margin-right: 32px !important;
}

.footer-container .barcode-container img {
  height: 140px;
  width: 140px;
  /* margin-right: 80px; */
}

.align-fFlex-start {
  justify-self: flex-start;
}

.align-flex-end {
  justify-self: flex-end;
}

.justify-space-between {
  justify-content: space-between;
}

.gap-40 {
  gap: 40px;
}

.footer-container .footer-header {
  font-size: 25px;
  font-weight: 900;
}

.footer-container .footer-text {
  font-size: 16px;
  font-weight: 900;
  /* margin-right: 50px; */
}

.footer-container .footer-icon {
  font-size: 20px;
  font-weight: bold;
}

.footer-container .footer-arrow {
  justify-self: center;
  font-size: 35px;
  color: rgba(0, 0, 0, 0.66);
  width: 55px;
  height: 55px;
  font-weight: 100;
  border-radius: 50%;
  padding: 10px;
  background-color: var(--primary-color);
  cursor: pointer;
}

.footer-container .grid-four {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.footer-container .grid-footer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: center;
}

.footer-container .hr-footer {
  width: 85%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 5px;
  background-color: rgba(255, 255, 255, 0.786);
  color: rgba(255, 255, 255, 0.786);
}

.footer-button {
  width: 250px;
  height: 40px;
}

.m-5 {
  margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sm-long {
  min-width: 125px !important;
  font-size: 10px !important;
}

.intro-flex .sm-text {
  word-wrap: break-word;
  max-width: 500px;
}

.max-width-400 {
  max-width: 600px;
  font-family: 'Playfair Display', Nunito;
}


/* responsive design homepage */
@media (max-width: 1100px) {
  .homepage-container .box-center {
    width: 85%;
  }

  .homepage-container .nav-container .nav-logo img {
    width: 150px;
    height: 40px;
    object-fit: contain;
    cursor: pointer;
  }

  .apartments-container .apartments-grid {
    grid-template-columns: 1fr 1fr;
  }

  .reviews-container .item-container {
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 30px;
    box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.1);
  }

  .apartments-slide-three {
    width: 90% !important;
    margin-bottom: 50px;
  }
}

@media (min-width: 1001px) {
  .menu-wrap {
    display: none !important;
  }

  .mobile-container {
    display: none;
  }

  .apartments-slide-two {
    width: var(--mobile-container) !important;
    margin-bottom: 50px;
    display: none !important;
    margin: auto;
  }

  .apartments-slide-one {
    display: none !important;
  }

  .mobile-search {
    display: none;
  }
}

@media (max-width: 1000px) {
  .nav-container {
    display: none !important;
  }

  /* .lower-nav-container{
    display: none !important;
  } */
  .footer-container .flex-space-between {
    /* width: 100%;
    padding:30px 30px; */
    justify-content: center;
  }

  .footer-container .logo-footer img {
    width: 110px;
    height: 30px;
  }

  .footer-container .barcode-container img {
    height: 40px;
    width: 40px;
  }

  .footer-container .footer-header {
    font-size: 18px;
  }

  .footer-container .footer-text {
    font-size: 14px;
  }

  .footer-container .footer-icon {
    font-size: 16px;
  }

  .footer-container .footer-arrow {
    font-size: 28px;
    width: 45px;
    height: 45px;
  }

  .footer-button {
    width: 180px;
    height: 30px;
  }

  .reviews-sm {
    font-size: 10px;
  }

  .reviews-review {
    font-size: 12px;
  }

  .homepage-container .hero-container {
    height: calc(70vh - 40px);
  }

  .homepage-container .hero-container .swipper-overlay-hero {
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: calc(70vh - 40px);
    z-index: -1;
  }

  .homepage-container .box-center {
    display: none !important;
    width: 95%;
  }

  .apartments-slide-one {
    display: none !important;
  }

  .apartments-slide-two {
    width: 90% !important;
    margin-bottom: 50px;
  }

  .apartments-slide-three {
    display: none !important;
  }

  .services-container,
  .apartments-container {
    width: var(--mobile-container);
  }
}

@media (max-width: 850px) {
  .reviews-sm {
    font-size: 10px;
  }

  .reviews-review {
    font-size: 12px;
  }

  .apartments-container .apartment-title-and-price .apartment-title {
    font-size: 20px;
  }

  .apartments-container .apartments-grid .item.instagram .apartment-image .img-apartment img {
    width: 300px;
    height: 200px;
  }

  .reviews-container .item-container {
    padding: 20px;
  }
}

@media (min-width: 701px) {
  .hide-701 {
    display: none !important;
  }
}

@media (max-width: 700px) {
  .hr-footer {
    display: none;
  }

  .footer-container .flex-space-between.desktop {
    display: none !important;
  }

  .footer-container .flex-space-between.mobile {
    display: flex;
  }

  .logo-footer,
  .footer-button-container {
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .homepage-container .hero-container {
    height: 65vh !important;
    width: 100%;
  }

  /* hero overlay */
  .homepage-container .hero-container .swipper-overlay-hero {
    position: absolute;
    top: 115px;
    left: 0;
    width: 100%;
    height: 65vh !important;
    z-index: -1;
  }

  .footer-container .flex-space-between {
    width: 100%;
    padding: 30px 5px;
  }

  .sm-long {
    min-width: 120px !important;
    font-size: 10px !important;
  }

  .services-container .items-grid {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    width: 90%;
  }

  .apartments-container .apartments-grid {
    grid-template-columns: 1fr !important;
    padding-left: 10%;
    padding-right: 10%;
  }

  .services-container .items-grid .item.flex-column.one,
  .services-container .items-grid .item.flex-column.two,
  .services-container .items-grid .item.flex-column.three {
    border-bottom: 0.5px solid #cba035ba;
    border-right: none;
    border-left: none;
    border-top: none;
  }

  .services-container .items-grid .item.flex-column.four {
    border: none;
  }

  .apartments-container .apartments-grid .item.instagram .apartment-image .img-apartment img {
    width: 100%;
    height: 100%;
  }

  .swipper-apartment-image {
    width: 350px;
    height: 250px;
  }

  .apartments-container .apartments-grid .item.instagram .apartment-image .img-apartment img {
    width: 250px;
    height: 180px;
  }

  .reviews-review {
    font-size: 12px;
  }

  .reviews-sm {
    font-size: 10px;
  }

  .hide-700 {
    display: none !important;
  }

  .footer-container {
    width: 100%;
    background: url(../public/images/web\ black\ 1.png);
    color: white;
  }

  .footer-container .flex-space-between {
    gap: 70px;
    width: 80%;
    height: 220px;
    padding-top: 30px;
  }

  .footer-container .logo-footer img {
    width: 130px;
    height: 40px;

  }

  .footer-container .barcode-container img {
    height: 60px;
    width: 60px;
  }

  .copyright-text-footer {
    font-size: 12px !important;
  }

  .align-flex-start {
    justify-self: flex-start;
  }

  .align-flex-end {
    justify-self: flex-end;
  }

  .justify-space-between {
    justify-content: space-between;
  }

  .gap-40 {
    gap: 40px;
  }

  .footer-container .footer-header {
    font-size: 18px;
  }

  .footer-container .footer-text {
    font-size: 14px;
  }

  .footer-container .grid-four {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .footer-container .flex-space-between {
    gap: 70px;
    width: 80%;
    height: auto;
    padding-top: 30px;
  }

  .reviews-container .item-container {
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 40px;
    margin-right: 40px;
    padding: 10px;
  }

  .reviews-container {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100% !important;
    margin: auto;
  }

  .apartments-slide-one {
    width: 80% !important;
    margin-bottom: 50px;
  }
}

@media (max-width: 550px) {
  .homepage-container .lower-nav-container .container-flex-lower-nav {
    /* width: 85%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    font-size: 11px;
    text-transform: uppercase;
  }

  .hide-545 {
    display: none;
  }

  .apartments-container .apartments-grid .item.instagram .apartment-image .img-apartment img {
    width: 200px;
    height: 150px;
  }

  .footer-container .grid-footer {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .swipper-apartment-image {
    width: 300px;
    height: 150px;
  }

  .apartments-container .apartments-grid .item.instagram .apartment-image .img-apartment img {
    width: 100%;
    height: 100%;
  }

  .footer-container .flex-space-between {
    gap: 20px;
    width: 90%;
    height: auto;
    padding-top: 30px;
  }

  .intro-flex .big-text {
    font-size: 28px;
  }

  .intro-flex .sm-text {
    font-size: 18px;
    color: white;
    font-weight: 900;
    z-index: 10;
    max-width: 350px;
  }

  .newsletter-input input {
    min-width: 200px;
  }

  .section-text {
    font-size: 28px;
  }

  .apartments-slide-two {
    display: none !important;
  }

  .apartments-slide-one {
    width: var(--container);
    margin-bottom: 50px;
    display: block !important;
  }

  .apartments-slide-three {
    display: none !important;
  }

  .services-container .items-grid .item.flex-column {
    justify-content: center;
    align-items: center;
    padding: 30px 10px;
    gap: 20px;
    height: 200px !important;
  }

  .services-container .items-grid .item.flex-column .title {
    font-size: 18px;
  }

  .services-container .items-grid .item.flex-column .desc {
    font-size: 14px;
  }

  .swiper-review-item {
    min-height: 200px;
    width: 350px;
  }
}

@media (max-width: 380px) {
  .homepage-container .lower-nav-container .container-flex-lower-nav {
    /* width: 95%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .newsletter-input input {
    min-width: 150px;
  }

  .swiper-review-item {
    min-height: 200px;
    width: 300px;
  }

  .homepage-container .lower-nav-container {
    font-size: 14px;
  }
}

@media (max-width: 350px) {
  .intro-flex .big-text {
    font-size: 25px;
  }

  .intro-flex .sm-text {
    font-size: 16px;
    color: white;
    font-weight: 900;
    z-index: 10;
    max-width: 250px;
  }

  .section-text {
    font-size: 25px;
    font-weight: 900;
    text-align: center;
  }

  .sm-long {
    max-width: 80px !important;
    font-size: 10px !important;
  }

  .swiper-review-item {
    height: 200px;
    width: 300px;
  }
}

@media (max-width: 330px) {
  .intro-flex .big-text {
    font-size: 24px;
  }

  .intro-flex .sm-text {
    font-size: 16px;
    color: white;
    font-weight: 900;
    z-index: 10;
    max-width: 250px;
  }

  .section-text {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
  }

  .swiper-review-item {
    height: 200px;
    width: 280px;
  }
}

.hamburger-button {
  height: 40px;
  width: 160px;
  border: 2px solid #CBA135;
  font-size: 14px;
  padding: 15px;
  margin-top: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hamburger-button.sm {
  color: black !important;
}

.hamburger-button.white {
  border: 2px solid var(--primary-color) !important;
  padding: 15px;
  background-color: inherit;
}







/* LOGIN PAGE */
.login-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  margin: 0;
  gap: 20px;
  padding: 50px;
  width: 70%;
  margin: auto;
}

/* .login-form.flex-column{
  transform: scale(0.95);
} */
.sign-up-google-apple.flex-row.center img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.image-container {
  position: relative;
}

.login-container .mobile-logo {
  display: none;
}

.image-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.big-login-image {
  height: 500px;
  width: 350px;
}

.login-logo {
  width: 200px;
  height: 80px;
}

.login-container .title-container {
  margin-bottom: 20px;
}

.login-container .title-container .title-text {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 64px;
}

.login-container .title-container .title-sm {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 33px;
}

.login-container .input-flex .input-container .input-title {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22.34px;
}

.login-container .input-flex .input-container .input {
  background-color: inherit;
  border: 1px solid #49433a5f;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.checkbox-container {
  align-items: center;
}

.checkbox-container input {
  height: 25px;
  width: 25px;
}

.check-box-sm {
  font-size: 14px;
}

.login-container .input-flex .input-container .input i {
  color: #49433A;
}

.login-container .input-flex .input-container input {
  background-color: inherit;
  outline: none;
  border: none;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.login-container .forgot-password-text {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21.82px;
  text-align: right;
  color: #D4B157;
  margin-top: 10px;
  cursor: pointer;
}

.login-container .forgot-password-text:hover {
  text-decoration: underline;
}

.login-container .login-button.flex-row.center {
  margin: auto;
  margin-top: 10px;
  width: 180px;
}

.or-container {
  width: 100%;
  margin-top: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 33px;
  color: #49433A;
}

.or-container .dash-logo {
  width: 50%;
  height: 1px;
  background-color: #49433a5f;
}

.sign-up-google-apple.flex-row.center {
  width: 100px;
  height: 35px;
  border: 1px solid #49433a5f;
}

.m-bottom-50px {
  margin-bottom: 50px;
}

.sign-up-container.flex-row.center {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 33px;
  color: #1D1B17;
}

.sign-up-container.flex-row.center .orange-sm {
  color: var(--primary-color);
}

.sign-up-container.flex-row.center .orange-sm:hover {
  text-decoration: underline;
  cursor: pointer;
}

.brown-text {
  color: var(--primary-color);
}

.bold {
  font-weight: 600 !important;
}

@media (max-width: 1100px) {
  .login-container {
    width: 90%;
  }
}

@media (max-width: 900px) {
  .login-container {
    width: 80%;
    padding: 20px;
  }

  .big-login-image {
    height: 400px;
    width: 300px;
  }

  .login-logo {
    width: 200px;
    height: 80px;
  }
}

@media (max-width: 800px) {
  .login-container {
    width: 95%;
    padding: 20px;
  }

  .big-login-image {
    height: 450px;
    width: 300px;
  }

  .login-logo {
    width: 160px;
    height: 60px;
  }
}

@media (max-width: 650px) {
  .login-container {
    width: 95%;
    padding: 20px;
  }

  .big-login-image {
    height: 450px;
    width: 250px;
  }

  .login-logo {
    width: 160px;
    height: 60px;
  }
}

@media (max-width: 580px) {
  .login-container {
    width: 98%;
    padding: 20px;
  }

  .big-login-image {
    height: 450px;
    width: 250px;
  }

  .login-logo {
    width: 160px;
    height: 60px;
  }

  .login-container .title-container .title-text {
    font-size: 28px;
    text-align: center;
  }

  .login-container .title-container .title-sm {
    font-size: 16px;
  }
}

@media (min-width: 521px) {
  .login-container {
    height: 100vh;
  }
}

@media (max-width: 520px) {
  .login-container {
    width: 90%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr;
    gap: 50px;
  }

  .login-container .image-container {
    display: none;
  }

  .login-container .mobile-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-container .mobile-logo img {
    width: 220px;
    object-fit: contain;
  }

  .login-form.flex-column {
    width: 100%;
  }

}

@media (max-width: 350px) {
  .login-container {
    width: 85%;
  }
}

/* single apartment */
.single-apartment-container {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.overlay-container-single {
  width: 100%;
  display: flex;
  justify-content: center;
}

.overlay-container-single .top {
  display: flex;
  width: 100%;
  height: 60vh;
  /* change this to adjust the height of top image, with the milano background logo */
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: 100% !important;
}

.overlay-container-single .top.top-single-apartment {
  margin-bottom: calc(50vh);
  /* set this to height of the slider - distance of the slider from the top of the screen*/
}


.overlay-container-single .top img {
  min-width: 100%;
  max-width: 100%;
  height: auto;
}

.overlay-container-single .logo-single-apartment {
  position: absolute;
  top: 170px;
  width: 250px;
  height: 150px;
}

.overlay-container-single .logo-single-apartment img {
  width: 100%;
  height: auto;
}

.review-page-container .top {
  display: flex;
  width: 100%;
  height: 60vh;
  /* change this to adjust the height of top image, with the milano background logo */
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: 100% !important;
}


.review-page-container .top img {
  width: 100%;
  height: auto;
}



.single-apartment-container .swiper-single {
  width: 100%;
}

.single-apartment-container .apartment-slide {
  min-width: 90% !important;
  max-width: 90% !important;
  height: calc(90vh - 20px);
  /* adjust this to change the height of the slider*/
  position: absolute;
  top: 30vh;
  /* adjust this to change the distance of the slider from the top of the screen*/
  border-radius: 10px;
  overflow: hidden !important;
  margin-top: 120px !important;
}

.single-apartment-container .apartment-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Home image slider */
.image__slider {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: black !important;
  font-size: 5px !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 15px !important;
}

.single-apartment-container .hero-links {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 50px;
  margin-top: 10vh;
}

.swipper-container-single-apartment {
  width: 100%;
}

.add-review-link {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #29200B;
  /* padding-bottom: 5px; */
  border-bottom: 2px solid var(--primary-color);
  cursor: pointer;
  height: min-content;
}

.price-single {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #000000;
}

.single-apartment-container .sm {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}

.single-apartment-container .apartment-info {
  align-self: flex-start;
  /* padding: 0 2.5%; */
  margin-bottom: 50px;
  width: 90%;
  margin: auto;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: var(--black) !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: var(--light-color) !important;
}

.single-apartment-container .apartment-info .number {
  color: white;
  height: 40px;
  width: 40px;
  font-size: 20px;
  border-radius: 50%;
  background-color: var(--primary-color);
}

.single-apartment-container .apartment-info img {
  max-width: 100%;
  /* height: 700px; */
}

.number-dash {
  align-items: center;
}

.single-apartment-container .feature-icon img {
  height: 20px;
}

.feature-icon.prime img {
  height: 30px !important;
}

.feature-icons-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 50%;
}

.single-apartment-container .title {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  color: #000000;
}

.single-apartment-container .feature-sm {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
}

.single-apartment-container .desc {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 170%;
  color: #000000;
}

.single-apartment-container .single-apartment-feature .sm {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #1D1B17;
}

.single-apartment-container .apartment-rules {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1D1B17;
}

.single-apartment-container .form-container {
  max-width: 100% !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0;
  padding: 0;
  gap: 20px;
}

.single-apartment-container .form-container input,
.single-apartment-container .form-container select {
  min-width: 450px;
  height: 35px;
  background-color: inherit;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
  cursor: pointer;
  z-index: 1000;
}

.single-apartment-container .form-container input[type="datetime-local"] {
  border: none;
  color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  text-align: right;
  height: 20px;
  display: inline-flex !important;
}

.single-apartment-container .cta-btns {
  /* justify-content: space-between; */
  gap: 50px;
}

.single-apartment-container .cta-btns.one {
  margin: 10px 20px 100px 20px;
  gap: 50px;
}

.single-apartment-container .cta-btns>div {
  width: 100px;
  height: 40px;
  border: 2px solid var(--primary-color);
  font-size: 14px;
  color: black;
  background-color: inherit;
  border-radius: 5px;
  cursor: pointer;
}

.single-apartment-container .cta-btns .pay-cta {
  /* background-color: var(--primary-color);
  color: var(--dark-color);
  min-width: 100px;
  color: var(--black); */
}

.single-apartment-container .input-title {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #1D1B17;
}

.hide {
  display: none !important;
}

.mobile-nav-apartment {
  width: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  display: none;
}

.mobile-nav-apartment .inactive {
  background-color: #D9D9D9 !important;
  border-color: #D9D9D9 !important;
}

.mobile-nav-apartment .number.flex-row.center {
  height: 30px !important;
  min-width: 30px !important;
  border-radius: 50%;
  background-color: var(--primary-color);
  font-size: 12px;
}

.line-dash.rotate-180 {
  border: 1px dotted var(--primary-color);
  width: 100%;
}

.dash-2 img {
  height: 550px !important;
}

@media (max-width: 1100px) {

  .single-apartment-container .form-container input,
  .single-apartment-container .form-container select {
    min-width: 400px;
  }

  .single-apartment-container .single-apartment-feature .sm {
    font-size: 14px;
  }

  .input-dob input,
  .dob-input {
    width: 40px !important;
  }
}

@media (max-width: 950px) {
  .single-apartment-container .form-container input {
    min-width: 370px;
  }

  .single-apartment-container .single-apartment-feature .sm {
    font-size: 12px;
  }

}

@media (max-width: 880px) {
  .single-apartment-container .form-container input {
    min-width: 320px !important;
  }

}

@media (max-width: 850px) {
  .line-dash img {
    height: 800px !important;
  }


}

@media (max-width: 790px) {

  .single-apartment-container .form-container input,
  .single-apartment-container .form-container select {
    min-width: 280px !important;
  }

  .price-single {
    font-size: 28px;
  }

  .feature-icons-grid {
    width: 60%;
  }

  .single-apartment-container .logo-single-apartment {
    position: absolute;
    top: 170px;
    width: 200px;
    height: 150px;
  }

}

@media (max-width: 700px) {
  .single-apartment-container .form-container input {
    min-width: 250px !important;
  }

  .feature-icons-grid {
    width: 80%;
  }

  .line-dash img {
    height: 900px !important;
  }
}

@media (max-width: 650px) {

  /* .swipper-container-single-apartment {
    margin-top: -170px;
  } */
  .single-apartment-container .form-container input {
    min-width: 220px !important;
  }

  .number-dash,
  .number-payment-form {
    display: none;
  }

  .single-apartment-container .title {
    font-size: 25px;
  }

  .hide-650 {
    display: none;
  }

  .single-apartment-container .form-container {
    margin: auto;
    justify-content: center;
    align-items: center;
  }

  .mobile-nav-apartment {
    display: flex;
  }

  .flex-column.personal-details-form {
    margin: auto;
  }

  .single-apartment-container .apartment-slide {
    height: calc(60vh - 20px);
    /* adjust this to change the height of the slider*/
  }

  .overlay-container-single .top.top-single-apartment {
    margin-bottom: calc(20vh);
    /* set this to height of the slider - distance of the slider from the top of the screen*/
  }

  .swiper-pagination-bullet {
    background: rgb(255, 255, 255, 1) !important;
    opacity: 1 !important;
  }

  .swiper-pagination-bullet-active {
    background: var(--primary-color) !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-color: white;
    height: 30px !important;
    width: 30px !important;
    border-radius: 50%;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 8px !important;
  }
}

@media (max-width: 570px) {
  .single-apartment-container .form-container input {
    min-width: 200px !important;
  }

  .line-dash img {
    height: 950px !important;
  }

}

@media (max-width: 530px) {
  .single-apartment-container .form-container {
    grid-template-columns: 1fr;
  }

  .single-apartment-container .form-container input {
    min-width: 320px !important;
  }

  .single-apartment-container .cta-btns {
    margin: 10px 0px 100px 0px;
    gap: 0px;
    justify-content: space-between;
  }

  .single-apartment-container .feature-sm {
    font-size: 20px;
  }

  .single-apartment-container .apartment-rules {
    font-size: 14px;
  }

  .single-apartment-container .desc {
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .line-dash img {
    height: 1000px !important;
  }

  .single-apartment-container .logo-single-apartment {
    position: absolute;
    top: 170px;
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 430px) {
  .single-apartment-container .form-container input {
    min-width: 280px !important;
  }

  .feature-icons-grid {
    width: 95%;
  }

  .line-dash img {
    height: 1050px !important;
  }

  .price-single {
    font-size: 22px;
  }

  .single-apartment-container .sm {
    font-size: 14px;
  }

  .add-review-link {
    font-size: 20px;
  }
}

@media (max-width: 400px) {
  .line-dash img {
    height: 1100px !important;
  }
}

@media (max-width: 375px) {
  .line-dash img {
    height: 1150px !important;
  }
}


@media (max-width: 350px) {

  .single-apartment-container .form-container input,
  .single-apartment-container .form-container select {
    min-width: 220px !important;
  }

  .line-dash img {
    height: 1300px !important;
  }
}

.ride-page-container {
  background: var(--white-color, #F3F2EF);
  background: url(../public/images/bar-new-bg.png) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: 50% !important;

}

.food-container {
  background: var(--white-color, #F3F2EF);
  background: url(../public/images/foodbg.png) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: 50% !important;
}

.food-page-container {
  height: calc(100vh - 120px);
  background: url(../public/images/food-bg.jpg);
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  overflow: hidden;
  padding: 0px 50px;
}

.food-page-container.ride-container .ride {
  height: calc(100vh - 120px);
  background: url(../public/images/ride-bg.jpg) !important;
  color: black;
}

.food-page-container img {
  max-width: 100%;
  transform: scale(1.7);
  position: relative;
  bottom: 50px;
  z-index: 1;
}

.food-page-container .ride-car-image img {
  max-width: 100%;
  transform: scale(1.2);
  position: relative;
  bottom: 50px;
  z-index: 1;
}

.food-page-container .text-container {
  text-align: left;
  z-index: 2;
}

.food-page-container .text-container.ride-page {
  width: 90%;
}

.food-page-container .text-container .big {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 55px;
  line-height: 150.8%;
}

.laundry-page-container .big {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 150.8%;
}

.food-page-container .text-container .sm,
.laundry-page-container .sm {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 165.8%;
}

.button.brown.food {
  height: 50px;
}

.laundry-page-container {
  height: calc(100vh - 120px);
  background: url(../public/images/laundry-bg.png);
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: 50% !important;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
}

.laundry-page-container.bar {
  height: calc(100vh - 120px);
  background: url(../public/images/bar-bg.jpg);
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: 50% !important;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
}

.laundry-page-container.food {
  height: calc(100vh - 120px);
  background: url(../public/images/foodbg.png);
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: 50% !important;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
}

.laundry-page-container.bar {
  background: url(../public/images/bar-bg.jpg) !important;
}

.laundry-page-container.ride {
  background: url(../public/images/ride-bg.jpg);
  overflow: visible;
}

.ride-categories-container {
  margin-top: 20px;
}

.ride-categories-container-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.ride-categories-container-grid .image-container {
  width: 370px;
  height: 250px;
}

.ride-categories-container-grid .image-container img {
  max-width: 100%;
  height: auto;
}

.ride-categories-container .section-title {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 64px;
  text-align: center;
  color: #000A1F;
}

.ride-categories-container-grid .car-name {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
}


@media (min-width: 1001px) {
  .laundry-page-container .big {
    max-width: 70%;
  }

  .hide-1001 {
    display: none !important;
  }
}

@media (max-width: 1400px) {
  .ride-categories-container-grid .image-container {
    width: 320px;
    height: 220px;
  }
}

@media (max-width: 1200px) {
  .ride-categories-container-grid .image-container {
    width: 280px;
    height: 190px;
  }
}

@media (max-width: 1050px) {
  .ride-categories-container-grid .image-container {
    width: 230px;
    height: 160px;
  }
}

@media (max-width: 850px) {
  .ride-categories-container-grid .image-container {
    width: 150px;
    height: 120px;
  }
}

@media (max-width: 600px) {
  .ride-categories-container .section-title {
    font-size: 30px;
  }

  .ride-categories-container-grid {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
}


@media (max-width: 1000px) {
  .food-page-container img {
    transform: scale(1.5);
    position: relative;
    bottom: 50px;
  }

  .ride-page-container img {
    max-width: 100%;
    transform: scale(1.1);
    position: relative;
    bottom: 50px;
  }

  .food-container img {
    max-width: 100%;
    transform: scale(1.1);
    position: relative;
    bottom: 50px;
  }

  .food-page-container .text-container .big {
    font-size: 45px;
  }

  .laundry-page-container .big {
    font-size: 45px;
  }

  .food-page-container .text-container .sm {
    font-size: 20px;
  }

  .button.brown.food {
    height: 50px;
  }

  .hide-1000 {
    display: none !important;
  }
}

@media (max-width: 885px) {
  .food-page-container img {
    transform: scale(1.7);
    position: relative;
    bottom: 0px;
  }

  .ride-page-container img {
    max-width: 100%;
    transform: scale(1.1);
    position: relative;
    bottom: 50px;
    z-index: 1;
  }

  .food-container img {
    max-width: 100%;
    transform: scale(1.1);
    position: relative;
    bottom: 50px;
    z-index: 1;
  }

  .food-page-container .text-container .big {
    font-size: 35px;
  }

  .laundry-page-container .big {
    font-size: 45px;
  }

  .food-page-container .text-container .sm {
    font-size: 20px;
  }

  .laundry-page-container .sm {
    font-size: 20px;
  }

  .button.brown.food {
    height: 50px;
  }
}

@media (max-width: 750px) {
  .food-page-container {
    padding: 0px 30px;
  }

  .food-page-container img {
    transform: scale(1.7);
    position: relative;
    bottom: 0px;
  }

  .ride-page-container img {
    max-width: 100%;
    transform: scale(0.9);
    position: relative;
    bottom: 50px;
    z-index: 1;
  }

  .food-container {
    max-width: 100%;
    transform: scale(0.9);
    position: relative;
    bottom: 50px;
    z-index: 1;
  }

  .food-page-container .text-container .big {
    font-size: 35px;
  }

  .laundry-page-container .big {
    font-size: 35px;
  }

  .food-page-container .text-container .sm {
    font-size: 20px;
  }

  .laundry-page-container .sm {
    font-size: 20px;
  }

  .button.brown.food {
    height: 50px;
  }
}

@media (max-width: 650px) {
  .food-page-container {
    padding: 0px 20px;
  }

  .food-page-container img {
    transform: scale(1) !important;
    position: relative;
    top: 10px;
  }

  .img-small {
    width: 400px !important;
  }

  .h11 {
    margin-top: 40px !important;
    font-size: 28px !important;
  }

  .h12 {
    margin-top: 40px !important;
    font-size: 28px !important;
  }

  .inBtw {
    display: grid !important;
    /* grid-template-rows: 2 !important; */
    align-items: center;
    margin-top: 20px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    justify-content: center !important;
    padding-left: 15% !important;
    padding-right: 15% !important;
  }

  .pText {
    font-size: 14px !important;
    width: fit-content !important;
    margin-left: 10px !important;
  }

  .div-center p {
    font-size: 15px !important;
  }

  .shirt {
    margin-left: 20px;
    width: 350px !important;
  }

  .barr {
    width: 350px !important;
  }

  .imgCenter {
    display: flex !important;
    justify-content: center !important;
  }

  .between-cars h1 {
    font-size: 24px;
  }

  .laundry-page-container .big {
    font-size: 25px;
  }

  .laundry-page-container .sm {
    font-size: 16px;
  }

  .button.brown.food {
    height: 50px;
  }
}

@media (max-width: 600px) {
  .food-page-container {
    padding: 0px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /*align-items: center; */
    overflow: visible !important;
    z-index: 2;
    height: fit-content;
    min-height: calc(100vh - 120px);
    gap: 0;
  }

  .food-page-container .img {
    order: 1;
  }

  .food-page-container .text-container {
    order: 2;
    position: relative;
    bottom: 50px;

  }

  .ride-page-container .text-container {
    order: 2;
    position: relative;
    bottom: 0px !important;

  }


  .food-page-container img,
  .ride-page-container img,
  .food-page-container .ride-car-image img {
    transform: scale(1);
    opacity: 1;
    z-index: 1;
    position: relative;
    bottom: 50px;
    overflow: hidden;
  }

  .food-page-container .text-container {
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 100px;
    padding: 0px 10px;
    z-index: 1000;
    gap: 35px;
  }

  .food-page-container .text-container.text-container.ride-page {
    width: 100%;
  }

  .food-page-container .text-container .big {
    font-size: 35px;
  }

  .food-page-container .text-container .sm {
    font-size: 16px;
  }

  .button.brown.food {
    height: 50px;
  }
}

.orange {
  color: var(--primary-color);
}

.orange2 {
  color: #CBA135;
}

.m-10-top {
  margin-bottom: 10px;
}

.position-relative {
  position: relative;
}

.color-black {
  color: var(--black);
  font-size: 12px;
}

.mobile-search {
  width: var(--mobile-container);
  border-radius: 10px;
  align-items: center;
  height: 80px;
  background-color: var(--light-color);
  position: relative;
  bottom: 45px;
  /* z-index: 10; */
  font-size: 12px;
  font-weight: 900;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  color: #7A6F60;
  padding: 20px;
  gap: 20px;
  font-size: 18px;
}

@media (min-width: 600px) {
  .mobile-search {
    justify-content: center;
  }
}

@media (max-width: 400px) {
  .mobile-search {
    font-size: 16px;
  }
}

@media (max-width: 350px) {
  .mobile-search {
    font-size: 14px;
    padding: 10px;
    gap: 10px;
  }
}

.white-text-search {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #F3F2EF;
}

.search-input-item {
  height: 45px;
  border: 1px solid white;
  align-items: center;
  padding: 10px;
  color: white !important;
  border-radius: 5px;
}

.search-input-item input {
  background-color: inherit;
  height: 100%;
  border: none;
  outline: none;
  color: white !important;
  width: 100%;
}

.search-input-item input::placeholder {
  color: white;
}

input[type="datetime-local"] {
  display: inline-flex !important;
}

.flex-row.gap-10.search-input-item .ext-sm {
  font-size: 14px;
}


/* review page */
.review-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 25%;
  padding-right: 25%;
  gap: 20px;
  background-color: white;
}

.flex-row.center.contact .review-form-container {
  background-color: var(--light-color);
}

.flex-row.center.contact {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 50px 10%;
  gap: 30px;
}

.form-container-review {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact .form-container-review {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flex-right-contact {
  width: 100%;
  justify-content: center;
}

.form-container-review.contact>div {
  width: 100%;
}

.form-container-review.contact>div input {
  width: 100%;
  height: 40px;
  background: inherit;
  outline: none;
  border: 1px solid #1e1e1e84;
  padding: 0px 20px;
}

.form-container-review .flex-input {
  min-width: 100%;
  justify-content: space-between;
  display: flex;
  gap: 20px;
}

.form-container-review .flex-input>div {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.form-container-review .flex-row.center.gap-20 input {
  width: 100%;
}

.rating-star-container {
  display: flex;
  /* align-self: flex-start; */
}

.review-form-container input,
textarea {
  width: 100%;
  height: 40px;
  background: inherit;
  outline: none;
  border: 1px solid #1e1e1e84;
  padding: 0px 20px;
}

.review-form-container input::placeholder,
textarea {
  color: rgba(0, 0, 0, 0.274);
}

.review-form-container i {
  color: var(--primary-color);
}

.review-form-container .input-title,
.form-container-review.contact .input-title {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

textarea {
  height: 150px;
  padding-top: 30px;
}

.section-title-review {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 64px;
  text-align: center;
  color: #000000;
}

.buttons-review {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-self: center;
  width: 100%;
  padding: 0px 50px;
}

.back-buttons-review {
  background: inherit;
  border: 1px solid var(--primary-color);
  padding: 5px 30px;
  height: 35px;
  width: 100px;
  border-radius: 5px;
  cursor: pointer;
}

.rating-star-container {
  align-self: flex-start;
}

.flex-left-contact img {
  max-width: 100%;
  height: auto;
}

.flex-right-contact .gold-title {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 53px;
  color: #876B23;
}

.flex-right-contact .title-sm {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: #475252;
}

.contact .details {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475252;
  padding: 0px 20px;
  border-left: 1px solid var(--primary-color);
}

.details.flex-row.gap-10 {
  align-items: center;
}

@media (max-width: 800px) {
  .flex-right-contact .gold-title {
    font-size: 30px;
  }

  .flex-right-contact .title-sm {
    font-size: 16px;
  }

  .contact .details {
    font-size: 14px;
  }

  .flex-row.center.contact {
    padding: 50px 5%;
    gap: 30px;
  }
}

@media (max-width: 600px) {
  .review-form-container {
    padding-left: 10%;
    padding-right: 10%;
  }

  .buttons-review {
    padding: 0px 10px;
  }

  .section-title-review {
    font-size: 30px;
  }
}

@media (max-width: 600px) {
  .flex-row.center.contact {
    display: flex;
    flex-direction: column-reverse;
    grid-template-columns: 1fr;
    padding: 50px 10%;
    gap: 30px;
  }

  .flex-left-contact .img {
    display: none;
  }

  .flex-right-contact .gold-title {
    font-size: 35px;
    line-height: 53px;
    color: inherit;
    text-align: center;
  }

  .flex-right-contact .title-sm {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 33px;
    color: #475252;
    text-align: center;
  }
}

@media (max-width: 450px) {
  .form-container-review .flex-input {
    display: flex;
    flex-direction: column !important;
  }
}

.forgot-password-container {
  width: 50%;
  margin: auto;
  margin-top: 15vh;
  gap: 50px;
  z-index: -1;
}

.forgot-password-logo img {
  width: 200px;
  height: 60px;
}

.forgot-password-logo .desc {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  padding: 0px 40px;
}

.forgot-password-input {
  width: 100%;
}

.forgot-password-input .input {
  width: 80%;
  align-items: center;
  text-align: left;
}

.forgot-password-input .input .input-container {
  width: 100%;
  align-items: center;
  border: 1px solid #49433A;
  padding: 0px 10px;
  color: #49433A;
}

.forgot-password-input .input input {
  width: 90%;
  height: 40px;
  background-color: inherit;
  outline: none;
  color: #49433A;
  padding: 10px;
  font-size: 14px;
  margin: auto;
  border: none;
  align-items: center;
}

.align-left {
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
}

@media (max-width: 1200px) {
  .forgot-password-container {
    width: 60%;
    margin: auto;
    margin-top: 15vh;
    gap: 50px;
  }
}

@media (max-width: 900px) {
  .forgot-password-container {
    width: 70%;
    margin: auto;
    margin-top: 15vh;
    gap: 50px;
  }
}

@media (max-width: 780px) {
  .forgot-password-container {
    width: 80%;
    margin: auto;
    margin-top: 15vh;
    gap: 50px;
  }
}

@media (max-width: 600px) {
  .forgot-password-container {
    width: 85%;
    margin: auto;
    margin-top: 15vh;
    gap: 50px;
  }

  .forgot-password-logo .desc {
    font-size: 18px;
    padding: 0px 10px;
  }
}

.forgot-password-overlay {
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  width: fit-content;
  margin: auto;
  padding: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20000;
  background-color: var(--light-color);
  min-width: 280px;
}

.check-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  font-size: 32px;
}

.success .check-container {
  height: 80px;
  width: 80px;
}

.success .check-container i {
  transform: scale(1.5);
}

.forgot-password-overlay .text {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 33px;
  color: #000000;
}

.forgot-password-overlay .back {
  border: 1px solid var(--primary-color);
}

.spinner {
  height: 25px;
  width: 25px;
  border-top: 2px solid var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  border-right: 2px solid var(--primary-color);
  border-left: 2px solid #842a831c;
  border-radius: 50%;
  animation: spinnerAnimation 0.5s 0s infinite linear forwards;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

@keyframes spinnerAnimation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}




/* terms and conditions */
.t-c-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: auto;
}

.t-c-container .t-c-items .flex-row {
  align-self: flex-start;
}

.t-c-container .t-c-items .flex-row>div,
.t-c-container .note {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #29200B;
}

.t-c-container .note {
  margin-top: 30px;
  padding: 20px;
}

.t-c-container .welcome {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #29200B;
  margin-top: 30px;
  margin-bottom: 50px;
}

.t-c-container .button.t-c {
  width: fit-content;
  height: 40px;
  font-size: 16px;
  border: 2px solid var(--primary-color);
  margin-bottom: 50px;
  padding: 12px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

.arrow-back-t-c {
  padding-left: 5%;
  margin-top: 50px;
  font-size: 30px;
  cursor: pointer;
}

.forgot-password-container.flex-column.center.t-c {
  margin-top: 30px;
}

.forgot-password-container.flex-column.center.t-c .t-c-header {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 64px;
  text-align: center;
  color: #1D1B17;
}

.t-c-items.flex-column.center.gap-20 .p-left {
  padding-left: 40px;
  width: 100%;
}

.t-c-items.flex-column.center.gap-20 .p-left>div {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #000000;
}

@media (max-width: 600px) {
  .forgot-password-container.flex-column.center.t-c .t-c-header {
    font-size: 20px;
  }

  .t-c-container .welcome {
    font-size: 18px;
  }

  .t-c-container .t-c-items .flex-row>div,
  .t-c-container .note {
    font-size: 14px;
  }

  .t-c-items.flex-column.center.gap-20 .p-left>div {
    font-size: 12px;
  }
}



/* ABOUT PAGE */
.about-container {
  width: 100%;
  margin: auto;
  margin-bottom: 100px;
  /* padding-left: 50px;
  padding-right: 50px; */
}

.about-container .section-title {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  color: #000A1F;
  margin: 30px 0px;
}

.about-container .grid-items {
  display: flex;
  flex-direction: column;
  gap: 80px;
  margin: 40px 0px;
}

.about-container .grid-items .grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  justify-content: center;
  align-items: center;
}

.about-container .grid-items .grid.one,
.about-container .grid-items .grid.two {
  margin-bottom: 100px;
  overflow: visible;
}

.about-container .grid-items .grid.one .about-desc {
  order: 2;
  transform: translateX(-10%);
  z-index: 20;
  background-color: #F5F5F5;
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.168);
  border-radius: 7px;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
}

.about-container .grid-items .grid.two .about-desc {
  order: 1;
  transform: translateX(10%);
  z-index: 20;
  background-color: #F5F5F5;
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.168);
  border-radius: 7px;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
}

.about-container .grid-items .grid.one .images {
  order: 1;
  height: 120%;
  transform: translateX(10%);
  z-index: 0;
}

.about-container .grid-items .grid.two .images {
  order: 2;
  transform: translateX(-10%);
  z-index: 0;
  height: 120%;
}

.about-container .grid-items .grid .images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px !important;
  height: 100%;
}

.about-container .grid-items .grid .images>div {
  width: 100%;
}

.about-container .grid-items .grid .images>div img {
  width: 100%;
  height: 100%;
}

.about-container .about-desc {
  height: 100%;
  justify-content: center;
}

.about-container .about-desc .title {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  color: #000000;
}

.about-container .about-desc .summary {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.grid.one .image-overlap {
  min-width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.no-wrap {
  word-break: keep-all;
  white-space: nowrap;
}

.about-container .grid.two .images {
  display: flex;
  height: 100%;
}

.grid-three-container {
  min-width: 100% !important;
  max-width: 100% !important;
  /* overflow: hidden !important; */
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  justify-content: center;
  gap: 0px;
}

.grid-three-container>div {
  width: 100%;
  height: 100%;
}

.grid-three-container>div img {
  width: 100%;
  /* transform: scale(2); */
}

.grid-three-container .image-overlap {
  position: relative;
}

.grid-three-container .image-overlap .one {
  z-index: 2;
  transform: translateX(55%);
}

@media (max-width: 1000px) {
  .about-container {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }

  .about-container .section-title {
    font-size: 35px;
  }

  .about-container .about-desc .title {
    font-size: 22px;
  }

  .about-container .about-desc .summary {
    font-size: 14px;
  }

  .about-container .grid-items .grid .images {
    height: fit-content !important;
  }
}

@media (max-width: 650px) {
  .about-container .grid-items {
    gap: 30px;
  }

  .about-container .grid-items .grid {
    gap: 30px;
  }

  .about-container .grid-items .grid.two {
    display: flex;
    flex-direction: column;
  }

  .grid-three-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
  }

  .grid-three-container .image-overlap .one {
    display: none;
  }
}

@media (max-width: 450px) {
  .about-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.swiper-button-next::after:hover,
.swiper-button-prev::after:hover {
  color: var(--light-color) !important;
}


.guest-container-mobile {
  justify-content: space-between !important;
}

.swiper-review-item {
  height: 200px;
  width: 100% !important;
}

.max-width-800px {
  max-width: 800px;
}

.margin-btm-50 {
  margin-bottom: 50px;
}

.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  margin-top: 0;
}

.text-center {
  text-align: center;
}

.dark-pry-color {
  color: #443612;
}

.dark-pry-color-btn {
  color: #876B23;
}


.ride-text {
  color: white;
}

.ride-page-container img {
  max-width: 100%;
  transform: scale(1.4);
  position: relative;
  bottom: 50px;
  z-index: 1;
}

.food-container img {
  max-width: 100%;
  transform: scale(1.4);
  position: relative;
  bottom: 50px;
  z-index: 1;
}

.h11 {
  text-align: center;
  font-size: 42px;
}

.h12 {
  margin-top: 40px;
  text-align: center;
  font-size: 38px;
}

.img-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.between-cars {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.div-center {
  display: flex;
  justify-content: center;
  margin-left: 10%;
  margin-right: 10%;

}

.div-center p {
  line-height: 40px;
  font-size: 18px;
}

.btnCenter {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  margin-bottom: 100px;
}

.inBtw {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-left: 10%;
  margin-right: 10%;
}

.pText {
  font-size: 18px;
  line-height: 40px;
}

.mb {
  margin-bottom: 100px;
}

.summary-container-dates {
  width: fit-content;
  width: 300px;
}

.summary .summary-dates {
  width: fit-content;
  width: 300px;
  height: 300px;
  background-color: #EAEAEA80;
  padding: 15px;
}

.gap-15 {
  gap: 15px;
}

.sp-between {
  justify-content: space-between;
}

.summary .flex-start {
  align-items: flex-start;
}

.summary .flex-end {
  align-items: flex-end;
}

.summary .room-title {
  text-align: center !important;
  font-size: 18px;
}

.summary .date-title {
  font-size: 16px;
}

.summary .date-title-sm {
  font-size: 12px;
  /* margin-top: 8px; */
}

.summary .faint {
  opacity: 0.8;
}

.summary .apartment-break-down {
  width: 300px;
  justify-content: space-between;
  height: 300px;
  background-color: #EAEAEA80;
  padding: 15px;
}

.full-width {
  width: 100%;
}

.three-grid {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  gap: 20px;
  width: 100%;
}

.three-grid>div {
  width: 100%;
}

.border-summary {
  border-left: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);
}

.summary .border-total {
  border-top: 1px solid rgba(0, 0, 0, 0.179);
  border-bottom: 1px solid rgba(0, 0, 0, 0.179);
  padding: 10px 0px;
}

.margin-btn-payment {
  margin-bottom: 50px;
}

.grid-summary-dates {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

@media (min-width: 701px) {
  .hide-701 {
    display: none;
  }
}

@media (max-width: 700px) {
  .flex-column-700 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .margin-btn-payment.hide-701 {
    gap: 50px;
    justify-content: space-between;
  }

  .margin-btn-payment.hide-701 .pay-cta {
    min-width: 100px;
    font-size: 12px;
  }
}

@media (max-width: 400px) {

  .summary .summary-dates,
  .summary .apartment-break-down {
    width: 250px;
    margin: auto;
  }

  .summary .room-title {
    font-size: 16px;
  }

  .summary .date-title {
    font-size: 14px;
  }

  .summary .date-title-sm {
    font-size: 12px;
  }

  .summary .date-title-sm.faint {
    font-size: 10px;
  }
}

.min-150 {
  min-width: 150px !important;
}

.align-center {
  text-align: center;
}

.pay-cta {
  height: 40px;
  border-radius: 5px;
}

/* about page responsive design */
@media (max-width: 1000px) {

  .grid.one,
  .grid.two,
  .grid {
    display: grid;
    grid-template-columns: 1fr !important;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .about-container .grid-items .grid.one .images,
  .about-container .grid-items .grid.one .about-desc,
  .about-container .grid-items .grid.two .images,
  .about-container .grid-items .grid.two .about-desc {
    transform: translateX(0);
  }

  .about-container .grid-items .grid.one .images,
  .about-container .grid-items .grid.two .images {
    order: 1 !important;
  }

  .about-container .grid-items .grid.one .about-desc,
  .about-container .grid-items .grid.two .about-desc {
    order: 2 !important;
  }

  .grid.one .about-desc {
    order: 2 !important;
  }

  .grid.one .images {
    order: 1 !important;
  }
}

@media (max-width: 500px) {

  .about-container .grid-items .grid.one .about-desc,
  .about-container .grid-items .grid.two .about-desc {
    padding: 20px;
  }
}

.single-apartment-container .form-container select {
  /* border: none; */
  outline: none;
}

.cta-btns.one.info {
  width: fit-content;
  max-width: 600px;
}

@media (max-width: 400px) {
  .img-small {
    width: 300px !important;
  }

  .shirt {
    margin-left: 20px;
    width: 300px !important;
  }
}

/* form input */
/* input[type="text"] {
  padding: 10px;
} */

input[type="date"] {
  padding: 10px;
}

/* About us text */
span.about-text {
  line-height: 34px;
}

span.about-texts {
  line-height: 28px;
}

/* steps of ordering spacing */

.apartment-info.flex-row.gap-20.false.payment-form {
  margin-top: 30px;
}

@media (max-width: 650px) {
  .hide-650 {
    display: none !important;
  }
}

.gallery-desktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  gap: 20px;
  overflow: hidden !important;
}

.gallery-desktop>div {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}

.gallery-desktop .one {
  grid-template-columns: 40% 60% !important;
}

.gallery-desktop .two {
  grid-template-columns: 60% 40% !important;
}

.gallery-desktop .three {
  grid-template-columns: 100% !important;
}

.gallery-desktop .four {
  grid-template-columns: 30% 70% !important;
}

.gallery-desktop>div>div {
  min-width: 100%;
  max-width: 100%;
}

.gallery-desktop>div>div img {
  min-width: 100%;
  max-width: 100%;
  min-height: 500px;
  max-height: 500px;
  object-fit: cover;
}

.visibility-none {
  visibility: hidden;
}

.triangle-square {
  width: 100px;
  /* Adjust this value to set the square's width */
  height: 70px;
  /* Adjust this value to set the square's height */
  background-color: var(--primary-color);
  /* Change the background color of the square */
  position: relative;
  margin-top: -20px;
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;

}

.triangle-square:hover {
  color: white
}

.lower-nav-link-item {
  text-align: center;
}

.kyc-btn {
  z-index: 10000000000000000;
}

.input-dob input,
.dob-input {
  width: 40px !important;
}

a {
  text-decoration: none !important;
  color: inherit;
  width: 100%;
}

.book-cta.dark-pry-color-btn.brown,
.button.brown,
.button.cta-review.white-button,
.text-center {
  text-align: center;
}

/* .full-desktop{
  width: var(--container) !important;
} */
.small-drop {
  width: 50px;
}

.grid-input {
  display: flex;
  flex-direction: column;
}

.grid-input input {
  min-width: 370px;
  height: 35px;
  background-color: inherit;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
  cursor: pointer;
  z-index: 1000;
}

.grid-input>div {
  max-width: 100px;
}

.grid-input select {
  /* width: 50px; */
  height: 35px;
  background-color: inherit;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
  cursor: pointer;
  z-index: 1000;
}

.id-input input {
  min-width: 450px;
  height: 35px;
  background-color: inherit;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
  cursor: pointer;
  z-index: 1000;
}

.number-title {
  min-width: fit-content;
}

.pay-cta.back {
  background-color: var(--light-color) !important;
}


@media (max-width: 530px) {
  .grid-input input {
    min-width: 280px !important;
  }
}

@media (max-width: 570px) {
  .grid-input input {
    min-width: 150px !important;
  }
}

@media (max-width: 650px) {
  .grid-input input {
    min-width: 170px !important;
  }
}

@media (max-width: 700px) {
  .grid-input input {
    min-width: 170px !important;
  }
}

@media (max-width: 790px) {
  .grid-input input {
    min-width: 230px !important;
  }
}

@media (max-width: 880px) {
  .grid-input input {
    min-width: 280px !important;
  }
}

@media (max-width: 950px) {
  .grid-input input {
    min-width: 320px;
  }
}

@media (max-width: 1100px) {
  .grid-input input {
    min-width: 350px;
  }
}

@media (max-width: 430px) {
  .grid-input input {
    min-width: 230px !important;
  }
}

@media (max-width: 350px) {
  .grid-input input {
    min-width: 170px !important;
  }
}

.click {
  cursor: pointer;
}