.carousel-images {
    position: relative;
    border-radius: 10px;
    /* height: 400px; */
    max-width: 650px;
    /* Adjusted for responsiveness */
    margin: auto;
    overflow: hidden;
}

.carousel-images img {
    width: 90%;
    /* Adjusted for responsiveness */
    height: 90%;
    /* Adjusted for responsiveness */
    object-fit: cover;
    /* Maintain aspect ratio */
    border-radius: 8px;
    border: #CBA135 solid 2px;
}

.slide_direction {
    display: flex;
    /* justify-content: space-between; */
}

.left,
.right {
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  /* position: absolute;
  top: 50%; */
  /* transform: translateY(-50%); */
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #CBA135;
}
/* 
.left {
  left: 10%;
}

.right {
  right: 10%;
} */



.carousel-indicator {
    /* margin-top: 20px; */
    display: flex;
    justify-content: center;
    gap: 20px;
}

.dot {
    background-color: #fff;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.active {
    background-color: #CBA135;
}

/* Media Queries for responsiveness */
@media screen and (max-width: 768px) {
    .carousel-images {
        /* height: 300px; */
        max-width: 500px;
    }

    .carousel-images img {
        width: 100%;
        height: 100%;
    }

    .carousel-indicator {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .carousel-images {
        /* height: 200px; */
        max-width: 300px;
    }

    .carousel-images img {
        width: 100%;
        height: 100%;
    }

    .carousel-indicator {
        display: none;
    }
}

@media screen and (max-width: 400px) {
    .carousel-images {
        /* height: 200px; */
        max-width: 250px;
    }

    .carousel-images img {
        width: 100%;
        height: 100%;
    }

    .carousel-indicator {
        display: none;
    }
}

@media screen and (max-width: 350px) {
    .carousel-images {
        /* height: 200px; */
        max-width: 200px;
    }

    .carousel-images img {
        width: 100%;
        height: 100%;
    }

    .carousel-indicator {
        display: none;
    }
}

@media screen and (max-width: 300px) {
    .carousel-images {
        /* height: 200px; */
        max-width: 150px;
    }

    .carousel-images img {
        width: 100%;
        height: 100%;
    }

    .carousel-indicator {
        display: none;
    }
   
}